// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H0jLBMrM15b4CVipG2ew{display:flex;align-items:center;justify-content:space-between;gap:10px;width:100%;padding:10px 0}.F1tj0l6Jtb91bq1xyVaV{display:flex;align-items:center;gap:5px;font-size:30px;font-weight:800;color:#fff}@media(max-width: 370px){.F1tj0l6Jtb91bq1xyVaV{font-size:26px}}.F1tj0l6Jtb91bq1xyVaV img{width:37px;height:37px;border-radius:50%;object-fit:contain}@media(max-width: 370px){.H0jLBMrM15b4CVipG2ew{gap:5px}}`, "",{"version":3,"sources":["webpack://./src/components/head/header.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CACA,eAAA,CACA,UAAA,CACA,yBAPF,sBAQI,cAAA,CAAA,CAEF,0BACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,yBAxBF,sBAyBI,OAAA,CAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  width: 100%;\n  padding: 10px 0;\n  &Logo {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 30px;\n    font-weight: 800;\n    color: #fff;\n    @media (max-width: 370px) {\n      font-size: 26px;\n    }\n    img {\n      width: 37px;\n      height: 37px;\n      border-radius: 50%;\n      object-fit: contain;\n    }\n  }\n  @media (max-width: 370px) {\n    gap: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `H0jLBMrM15b4CVipG2ew`,
	"headerLogo": `F1tj0l6Jtb91bq1xyVaV`
};
export default ___CSS_LOADER_EXPORT___;
