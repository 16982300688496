// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fx75TfRWAuX6jJELPeqq{margin-top:20px}.ouITYRpd9ZrIuDRGWJLg{display:flex;align-items:center;justify-content:center;gap:15px}.ZzTrAQsY98E3P7LKqy4e{border-radius:16px;background:rgba(255,255,255,.08);display:flex;align-items:center;justify-content:center;padding:12px;transition:all .3s ease 0s;width:max-content}.ZzTrAQsY98E3P7LKqy4e svg{height:40px;width:40px}.ZzTrAQsY98E3P7LKqy4e:hover{background:rgba(255,255,255,.12)}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAEF,sBACE,kBAAA,CACA,gCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,0BAAA,CACA,iBAAA,CACA,0BACE,WAAA,CACA,UAAA,CAEF,4BACE,gCAAA","sourcesContent":[".footer {\n  margin-top: 20px;\n  &Social {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 15px;\n  }\n  &Link {\n    border-radius: 16px;\n    background: rgba(255, 255, 255, 0.08);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 12px;\n    transition: all 0.3s ease 0s;\n    width: max-content;\n    svg {\n      height: 40px;\n      width: 40px;\n    }\n    &:hover {\n      background: rgba(255, 255, 255, 0.12);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `fx75TfRWAuX6jJELPeqq`,
	"footerSocial": `ouITYRpd9ZrIuDRGWJLg`,
	"footerLink": `ZzTrAQsY98E3P7LKqy4e`
};
export default ___CSS_LOADER_EXPORT___;
