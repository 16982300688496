import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Head } from '../../components/head';
import { Footer } from '../../components/footer';
import Button, { btnVariant } from '../../components/button';
import { SvgSelector } from '../../assets/svg-selector';
import Preview from '../../assets/img/deanon.png';
import DeWallet from '../../assets/img/dewallet.png';
import s from './home.module.scss';
export const Home = ({ selectedLanguage, setSelectedLanguage }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: "DEANON" }), _jsx("meta", { name: "description", content: "Swap DFC to DEANON" })] }), _jsxs("div", { className: "container", children: [_jsx(Head, { selectedLanguage: selectedLanguage, setSelectedLanguage: setSelectedLanguage }), _jsx("div", { className: s.preview, children: _jsx("img", { src: Preview, alt: "preview" }) }), _jsx("div", { className: s.previewSwap, children: t('preview.title') }), _jsx("h1", { className: s.previewTitle, children: "DEANON" }), _jsxs(Link, { to: "https://dexscreener.com/ton/eqd7fo64xzzomk8tnvjmciwqn2gg5_azo6k31-jiduktibgp", target: "_blank", className: s.previewChart, children: [t('preview.chart'), " ", _jsx(SvgSelector, { id: "chart" })] }), _jsxs("div", { className: s.previewActions, children: [_jsxs(Button, { variant: btnVariant.gradient, link: 'https://dedust.io/swap/DFC/DEANON', children: [_jsx(SvgSelector, { id: "dedust" }), t('preview.buy-dedust')] }), _jsxs(Button, { variant: btnVariant.gradient, link: 'https://t.me/dewallet', children: [_jsx("img", { src: DeWallet, alt: "dewallet" }), t('preview.buy-dewallet')] })] }), _jsx(Footer, {})] })] }));
};
