// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._rznsHysez3V7xSg1bfC{outline:none !important;border:none;border-radius:24px;padding:16px 32px;display:flex;align-items:center;justify-content:center;gap:16px;user-select:none;font-size:23px;font-weight:600;cursor:pointer;color:#fff}.KSj90V7VktvTjfJgktU6{background:rgba(0,0,0,0)}.CM75Ybm8LHfU1tw30ksq{border:2px solid rgba(0,0,0,0);animation:AdmH9S4RfAEZNRgIb_3U 2s linear infinite alternate;transition:all .3s ease 0s}.CM75Ybm8LHfU1tw30ksq:hover{border-color:#8c3aa0;background:#8c3aa0;transform:scale(0.993)}@keyframes AdmH9S4RfAEZNRgIb_3U{0%{border-color:#8c3aa0}100%{border-color:#c411f1}}`, "",{"version":3,"sources":["webpack://./src/components/button/button.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CACA,WAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA,CACA,UAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,8BAAA,CACA,2DAAA,CACA,0BAAA,CACA,4BACE,oBAAA,CACA,kBAAA,CACA,sBAAA,CAIJ,gCACE,GACE,oBAAA,CAEF,KACE,oBAAA,CAAA","sourcesContent":[".button {\n  outline: none !important;\n  border: none;\n  border-radius: 24px;\n  padding: 16px 32px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  user-select: none;\n  font-size: 23px;\n  font-weight: 600;\n  cursor: pointer;\n  color: #fff;\n}\n\n.primaryVariant {\n  background: transparent;\n}\n\n.gradientVariant {\n  border: 2px solid transparent; \n  animation: moveBorder 2s linear infinite alternate;\n  transition: all 0.3s ease 0s;\n  &:hover {\n    border-color: #8c3aa0;\n    background: #8c3aa0;\n    transform: scale(0.993);\n  }\n}\n\n@keyframes moveBorder {\n  0% {\n    border-color: #8c3aa0;\n  }\n  100% {\n    border-color: #c411f1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `_rznsHysez3V7xSg1bfC`,
	"primaryVariant": `KSj90V7VktvTjfJgktU6`,
	"gradientVariant": `CM75Ybm8LHfU1tw30ksq`,
	"moveBorder": `AdmH9S4RfAEZNRgIb_3U`
};
export default ___CSS_LOADER_EXPORT___;
