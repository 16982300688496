import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactCountryFlag from 'react-country-flag';
import s from './language-selector.module.scss';
import { SvgSelector } from '../../assets/svg-selector';
export const LanguageSelector = ({ selectedLanguage, setSelectedLanguage }) => {
    const languages = ['ru', 'us', 'ua', 'cn'];
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const selectedLanguageRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            selectedLanguageRef.current &&
            !selectedLanguageRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (_jsxs("div", { className: s.languageSelector, children: [_jsxs("div", { className: s.selectedLanguage, ref: selectedLanguageRef, onClick: toggleDropdown, children: [_jsx(ReactCountryFlag, { countryCode: selectedLanguage, svg: true, style: {
                            width: '27px',
                            height: '27px',
                            borderRadius: '50%',
                            objectFit: 'cover'
                        }, className: s.flagIcon, title: selectedLanguage }), _jsx("span", { className: s.languageName, children: selectedLanguage }), _jsx("div", { className: isOpen ? s.chevronExpanded : s.chevronCollapsed, children: _jsx(SvgSelector, { id: "chevron-bottom2" }) })] }), isOpen && (_jsx(motion.ul, { className: s.dropdown, initial: { opacity: 0, y: -10 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.2 }, ref: dropdownRef, children: languages
                    .filter(el => el !== selectedLanguage)
                    .map(language => (_jsxs(motion.li, { onClick: () => handleLanguageSelect(language), whileHover: { scale: 1.05 }, whileTap: { scale: 0.95 }, className: s.dropdownItem, children: [_jsx(ReactCountryFlag, { countryCode: language, style: {
                                width: '27px',
                                height: '27px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }, svg: true, className: s.flagIcon, title: language }), _jsx("span", { className: s.languageName, children: language })] }, language))) }))] }));
};
