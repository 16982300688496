import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import style from './button.module.scss';
export var btnVariant;
(function (btnVariant) {
    btnVariant["primary"] = "primaryVariant";
    btnVariant["gradient"] = "gradientVariant";
})(btnVariant || (btnVariant = {}));
const ButtonWrapper = ({ children, link }) => {
    const isLink = !!link;
    return isLink ? (_jsx(Link, { className: style.buttonWrapper, to: link, target: '_blank', children: children })) : (_jsx("div", { className: style.buttonWrapper, children: children }));
};
const Button = ({ children, disabled = false, variant = btnVariant.primary, icon = null, link, hasBorders = false, onClick, className }) => (_jsx(ButtonWrapper, { link: link, children: _jsxs("button", { disabled: disabled, className: classNames(style.button, className, { [style[variant]]: true }), onClick: onClick, children: [hasBorders && (_jsx("div", { className: classNames(style.button, { [style[variant + 'Border']]: true }) })), icon, children] }) }));
export default Button;
